<template>
  <portal to="assembling-actions">
    <div>
      <option-object
        v-for="(item, i) in data"
        :key="`${item.event}:${i}`"
        class="q-mr-sm"
        :data="item"
        @click="handleClick"
      />
    </div>
  </portal>
</template>

<script>
// Components
import OptionObject from './option-object.vue'

export default {
  name: 'OptionsCollection',
  components: {
    OptionObject
  },
  emits: ['click'],
  props: {
    data: {
      type: Array,
      default () {
        return []
      }
    },
    rawData: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  methods: {
    handleClick (data) {
      this.$emit('click', data)
    }
  }
}
</script>
